<template>
  <div class="cover" id="coverImage" v-if="storeConfig.hasCoverPage">
    <div
      class="cover__background mobile-only"
      :style="{
        'background-image': 'url(' + mobileBackground + ')',
      }"
    ></div>
    <div
      class="cover__background desktop-only"
      :style="{
        'background-image': 'url(' + desktopBackground + ')',
      }"
    ></div>

    <div class="cover__overlay"></div>
    <div class="cover__container">
      <div class="cover__content">
        <div class="cover__content-inner">
          <div class="cover__logo" v-if="storeConfig.logoUrl">
            <div class="content">
              <div class="logo">
                <img :src="storeConfig.logoUrl" :alt="storeConfig.title" />
              </div>
            </div>
          </div>
          <div class="cover__title">
            <div class="content">
              <h1 class="cover-title shadow">
                {{ pageConfig.title }}
              </h1>
            </div>
          </div>
          <div class="cover__subtitle cover-title--long">
            <div class="content">
              <h2 class="cover-text shadow">
                {{ pageConfig.text }}
              </h2>
            </div>
          </div>
          <div class="cover__cta">
            <div class="content">
              <button class="cover__button cover-button" @click="toCatalog()">
                <span class="cover__button-text">Shop Now</span>
              </button>
            </div>
          </div>
          <nav class="cover__social cover-text shadow" role="navigation">
            <div
              class="content"
              style="font-family: Dosis; color: rgba(255, 255, 255, 1)"
            >
              <ul class="social-media"></ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const on = (el, eventName, callback, isUseCapture) => {
  if (el.addEventListener) {
    el.addEventListener(eventName, callback, !!isUseCapture);
  } else {
    el.attachEvent("on" + eventName, callback);
  }
};

const off = (el, eventName, callback, isUseCapture) => {
  if (el.removeEventListener) {
    el.removeEventListener(eventName, callback, !!isUseCapture);
  } else {
    el.detachEvent("on" + eventName, callback);
  }
};

const doScroll = (el, height) => {
  const yPx = height;
  const xPx = 0;
  el.style.cssText +=
    ";-webkit-transform : translate3d(" +
    xPx +
    "px, " +
    yPx +
    "px, 0px);" +
    "transform : translate3d(" +
    xPx +
    "px, " +
    yPx +
    "px, 0px);";
};

export default {
  beforeMount() {
    // current user may not be available yet in beforeMount hook so vuex watcher is needed
    const self = this;
    const unsubscribeFromStoreWatch = this.$store.watch(
      (state) => state.store,
      (storeConfig) => {
        if (!storeConfig.hasCoverPage) {
          if (storeConfig.hasHomePage) {
            self.$router.replace({ name: "home" });
          } else {
            self.$router.push({ name: "catalog" });
          }
        }
      },
      { immediate: true }
    );
    this.$once("hook:beforeDestroy", unsubscribeFromStoreWatch);
  },
  computed: {
    ...mapGetters({ storeConfig: "store" }),
    pageConfig: function () {
      return this.storeConfig.coverPageConfig;
    },
    desktopBackground: function () {
      if (this.pageConfig.background && this.pageConfig.background.desktop) {
        return this.pageConfig.background.desktop;
      }
      if (this.pageConfig.background && this.pageConfig.background.mobile) {
        return this.pageConfig.background.mobile;
      }
      if (this.pageConfig.backgroundUrl) {
        return this.pageConfig.backgroundUrl;
      }
      return "";
    },
    mobileBackground: function () {
      if (this.pageConfig.background && this.pageConfig.background.mobile) {
        return this.pageConfig.background.mobile;
      }
      if (this.pageConfig.background && this.pageConfig.background.desktop) {
        return this.pageConfig.background.desktop;
      }
      if (this.pageConfig.backgroundUrl) {
        return this.pageConfig.backgroundUrl;
      }
      return "";
    },
  },
  methods: {
    toCatalog: function () {
      const el = document.getElementById("coverImage");
      const height = el.offsetHeight;
      const self = this;
      let duration = 0.5;

      let fired = false;

      const animateDone = () => {
        if (this.storeConfig.hasHomePage) {
          self.$router.push({ name: "home" });
        } else {
          self.$router.push({ name: "catalog" });
        }
        fired = true;
      };

      on(el, "webkitTransitionEnd", animateDone);

      setTimeout(() => {
        if (fired) return;
        animateDone();
      }, duration * 1000 + 25);
      doScroll(el, -height);
    },
  },
  mounted() {
    if (!this.storeConfig.hasCoverPage) return;
    const el = document.getElementById("coverImage");
    const self = this;
    let startY = 0;
    let isNavigated = false;
    on(
      el,
      "touchstart",
      (e) => {
        startY = e.targetTouches[0].pageY;
      },
      { passive: true }
    );

    on(
      el,
      "touchend",
      (e) => {
        if (isNavigated) return false;
        let endY = e.changedTouches[0].pageY;
        let sub = (endY - startY) / 800;
        if (sub < -0.1) {
          isNavigated = true;
          self.toCatalog();
        }
      },
      { passive: true }
    );

    let isMousedown = false;
    on(el, "mousedown", (e) => {
      isMousedown = true;
      startY = e.pageY;
    });

    on(el, "mouseup", (e) => {
      isMousedown = false;
    });

    on(el, "mousemove", (e) => {
      if (isNavigated) return false;
      if (!isMousedown) return false;
      let sub = (e.pageY - startY) / 800;

      if (sub < -0.1) {
        isNavigated = true;
        this.toCatalog();
      }
    });

    let debounceTimer,
      interval = 1200,
      debounce = true;
    let mousewheelType =
      document.mozFullScreen !== undefined ? "DOMMouseScroll" : "mousewheel";

    on(el, mousewheelType, (e) => {
      if (!debounce || isNavigated) {
        return;
      }
      debounce = false;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        debounce = true;
      }, interval);
      let detail = e.wheelDelta ? e.wheelDelta / 120 : e.detail * -1;
      if (detail < 0) {
        isNavigated = true;
        self.toCatalog();
      }

      if (e.preventDefault) {
        e.preventDefault();
      } else {
        return false;
      }
    });
  },
};
</script>

<style lang="scss">
@import "~@lib/styles/helpers";

.cover {
  position: relative;
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
  height: 100vh;

  &__background {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  &__overlay {
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    );
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__container {
    display: flex;
    position: relative;
    z-index: 100;
    flex-direction: column;
    max-width: 1184px;
    margin: 0 auto;
  }

  &__content {
    font-family: "Raleway", "sans-serif";
    color: white;
    margin-top: 20vh;
    text-align: center;
  }

  &__button {
    background: var(--c-dark);
    color: white;
    padding: 20px 40px;
    font-size: 20px;
    margin-top: 40px;

    @include for-desktop {
      padding: 24px 48px;
      font-size: 24px;
    }
  }

  &__logo img {
    width: auto;
    height: auto;
    max-width: 240px;
    max-height: 120px;
    margin: auto;
    background-color: currentColor;
    border: 4px solid currentColor;
    border-radius: 4px;

    @include for-desktop {
      max-width: 300px;
      max-height: 150px;
    }
  }

  &__subtitle {
    h2 {
      font-weight: 400;
    }
  }
}

.anim {
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 500ms ease-out 0s;
  transition: all 500ms ease-out 0s;
}
</style>
